<script>
import { VclCode } from 'vue-content-loading';
import { mapState } from "vuex";

import TimeAgo from 'vue2-timeago';
import {OrbitSpinner} from "epic-spinners";

import {get_acsrf_token} from "@/methods";

export default {
  components: {
    VclCode,
    //OrbitSpinner
  },
  props: {

  },
  computed: {

  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/sevDesk/widgets/accounting-score', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.score = data.score;

          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
  },
  created() {
    this.getData();
    this.timer = setInterval(() => {this.getData();}, 1000 * 60 * 5);
  },
  destroyed() {
    if(this.timer) clearInterval(this.timer);
  },
  data() {
    return {
      ready: false,
      error: false,
      score: 0.0,
      timer: null,
    }
  }
};
</script>

<template>
  <div v-if="ready">
    <div class="progress" style="height: 25px;">
      <div
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
          :aria-valuenow="score"
          class="progress-bar progress-bar-striped"
          :style="'width: '+score+'%;'"
          :class="{
            'bg-danger': (score <= 90),
            'bg-primary': (score > 90),
          }"
      />
    </div>
    <div class="text-center h5 text-uppercase mt-1 mb-0">{{score}} %</div>
    <div class="text-center h6 text-uppercase">Accounting Score</div>
  </div>
  <div v-else-if="error">
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card border border-danger">
          <div class="card-header bg-transparent border-danger">
            <h5 class="my-0 text-danger">
              <i class="far fa-exclamation-circle mr-3"></i> {{ $t('error.server.generic.title') }}
            </h5>
          </div>
          <div class="card-body">
            <h5 class="card-title mt-0">
              {{ $t('dashboard.error.info') }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <vcl-code :height="90"></vcl-code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
